import "./navbar.css";
import { NavLink } from "react-router-dom";
import logo from "../../content/logo.png";

export default function Navbar({
  scroll,
  homeRef,
  aboutRef,
  contactRef,
  projectsRef,
  menuOpen,
  toggleMenu,
}) {
  return (
    <nav className={menuOpen ? "navbar" : "navbar-mobile"}>
      <div className="nav-container">
        <img src={logo} className="nav-logo" />
        <div className={menuOpen ? "nav-links" : "nav-links mobile"}>
          <NavLink
            className="nav-link"
            activeClassName="active"
            to="/"
            onClick={() => scroll(homeRef)}
          >
            Home
          </NavLink>
          <NavLink
            className="nav-link"
            activeClassName="active"
            to="/about"
            onClick={() => scroll(aboutRef)}
          >
            About
          </NavLink>
          <NavLink
            className="nav-link"
            activeClassName="active"
            to="/projects"
            onClick={() => scroll(projectsRef)}
          >
            Projects
          </NavLink>
          <NavLink
            className="nav-link"
            activeClassName="active"
            to="/contact"
            onClick={() => scroll(contactRef)}
          >
            Contact
          </NavLink>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="mobile-menu-icon"
          viewBox="0 0 16 16"
          onClick={toggleMenu}
          style={menuOpen ? { display: 'none' } : {}}
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="mobile-menu-close-icon"
          viewBox="0 0 16 16"
          onClick={toggleMenu}
          style={menuOpen ? { display: 'block' } : {}}
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
        </svg>
      </div>
    </nav>
  );
}
