import { Link } from "react-router-dom";
import "./about.css";

import js from "../../content/js.png";
import reactjs from "../../content/reactjs.png";
import css from "../../content/css.png";
import html from "../../content/html.png";
import redux from "../../content/redux.png";
import nodejs from "../../content/nodejs.png";
import sass from "../../content/sass.png";



export default function About({
    aboutRef
}) {
  return (
    <div className="about" ref={aboutRef}>
      <div className="about-container">
        <div className="about-me-section">
          <h2>About Me :</h2>
          <p>
            I am 24 years old, living in Varna, Bulgaria. When I'm not coding I
            like working out, binging{" "}
            <Link
              to="https://www.imdb.com/title/tt0386676/"
              target="blank"
              className="about-link"
            >
              The Office
            </Link>{" "}
            and playing{" "}
            <Link
              to="https://en.wikipedia.org/wiki/World_of_Warcraft"
              target="blank"
              className="about-link"
            >
              World of Warcraft
            </Link>
            .
          </p>
        </div>
        <div className="skills-section">
          <h2>My Tech Stack :</h2>
          <div className="tech-stack">
            <div className="tech-stack-inner">
              <img src={js} className="tech-stack-img" />
              <span>Javascript</span>
            </div>
            <div className="tech-stack-inner">
              <img src={reactjs} className="tech-stack-img" />
              <span>ReactJS</span>
            </div>
            <div className="tech-stack-inner">
              <img src={css} className="tech-stack-img" />
              <span>CSS</span>
            </div>
            <div className="tech-stack-inner">
              <img src={html} className="tech-stack-img" />
              <span>HTML</span>
            </div>
            <div className="tech-stack-inner">
              <img src={redux} className="tech-stack-img" />
              <span>Redux</span>
            </div>
            <div className="tech-stack-inner">
              <img src={sass} className="tech-stack-img" />
              <span>Sass</span>
            </div>
            <div className="tech-stack-inner">
              <img src={nodejs} className="tech-stack-img" />
              <span>NodeJS</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
