import "./projects.css";

import wellhall from "../../content/wellhall.png";
import automania from "../../content/automania.png";
import quickstore from "../../content/quickstore.png";
import cartPage from "../../content/redux-cart-page.png";
import cartPage2 from "../../content/cartPage2.png";
import usersList from "../../content/users-list.png";
import keyboard from "../../content/keyboard.png";
import greet from "../../content/greet.png";

import { Link } from "react-router-dom";
import { useState } from "react";

const projects = [
  { title: "Hotel Landing Page", img: wellhall, url: "https://wellhallhotel.website/" },
  { title: "Car Listing Page", img: automania, url: "https://automania.website/" },
  { title: "Online Store", img: quickstore, url: "https://quickstoreonline.shop/" },
  {
    title:"Shopping Cart Page",
    img: cartPage,
    url: "https://github.com/asdwastaken/Redux-Cart-Page",
    repo: true,
  },
  {
    title:"Users List",
    img: usersList,
    url: "https://github.com/asdwastaken/Users-List-React-App",
    repo: true,
  },
  {
    title:"Store Product Page",
    img: cartPage2,
    url: "https://github.com/asdwastaken/Another-Redux-Cart-Project",
    repo: true,
  },
  {
    title:"Keyboard",
    img: keyboard,
    url: "https://github.com/asdwastaken/Keyboard-Vanilla-JS",
    repo: true,
  },
  {
    title:"Booking Page",
    img: greet,
    url: "https://github.com/asdwastaken/Greet-Project-Application",
    repo: true,
  },
];

export default function Projects({ projectsRef }) {
  const [hover, setHover] = useState(null);

  const handleMouseEnter = (index) => {
    setHover(index);
  };

  const handleMouseLeave = () => {
    setHover(null);
  };

  return (
    <div className="projects">
      <div className="projects-container">
        <h2 ref={projectsRef}>Projects :</h2>
        <div className="projects-grid">
          {projects.map((project, index) => {
            return (
              <div
                className="project"
                key={index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={project.img}
                  className={
                    hover === index ? "project-img hovered" : "project-img"
                  }
                />
                <span className="project-title">{project.title}</span>
                <Link to={project.url} target="blank" className="project-link">
                  {project.repo ? "View Repository" : "Visit Website"}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
