import "./home.css";
import image from "../../content/home-image.jpg";
import { Link } from "react-router-dom";
import CV from "../../Miroslav_Tsanev.pdf";

export default function Home({ homeRef }) {
  return (
    <div className="home" >
      <div className="home-container">
        <div className="home-headings-container">
          <div className="home-headings" ref={homeRef}>
            <h3>Hello, I am</h3>
            <h2>Miroslav Tsanev</h2>
            <h1 id="front-end">Front End</h1>
            <h1>Developer</h1>
          </div>
          <Link to={CV} target="blank" className="cv-link">
            View CV
          </Link>
        </div>
        <div className="home-image-container">
          <img src={image} className="home-image" />
        </div>
      </div>
    </div>
  );
}
