import './contact.css';
import { Link } from "react-router-dom";
import emailIcon from '../../content/gmail.png';
import instagramIcon from '../../content/instagram.png';
import facebookIcon from '../../content/facebook.png';
import linkedInIcon from '../../content/linkedin.png';

export default function Contact({ contactRef }) {
  return (
    <div className="contact" ref={contactRef}>
      <div className="contact-container">
        <div className="social">
            <Link to='mailto:miroo2306@gmail.com' className="social-link email"> Email</Link>
            {/* <img src={emailIcon} className='social-icon '/> */}
            <span>miroo2306@gmail.com</span>
        </div>
        <div className="social">
            <Link to='https://www.linkedin.com/in/miroslav-tsanev-9369b4267/'  className="social-link linkedin" >LinkedIn</Link>
            {/* <img src={linkedInIcon} className='social-icon '/> */}
            <span>Miroslav Tsanev</span>
        </div>
        <div className="social">
            <Link to='https://www.facebook.com/miro.tsanev'  className="social-link facebook" >Facebook</Link>
            {/* <img src={facebookIcon} className='social-icon '/> */}
            <span>@miro.tsanev</span>
        </div>
        <div className="social">
            <Link to='https://www.instagram.com/asdwastaken/'  className="social-link instagram" >Instagram</Link>
            {/* <img src={instagramIcon} className='social-icon '/> */}
            <span>@asdwastaken</span>
        </div>
      </div>
    </div>
  );
}
