import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import "./app.css";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import { useRef, useState } from "react";
import Contact from "./components/Contact/Contact";

function App() {
  const homeRef = useRef();
  const aboutRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: 'center'});
    setMenuOpen(false);
  };

  return (
    <div className="App">
      <header>
        <Navbar
          scroll={scroll}
          homeRef={homeRef}
          aboutRef={aboutRef}
          projectsRef={projectsRef}
          contactRef={contactRef}
          menuOpen={menuOpen}
          toggleMenu={toggleMenu}
        />
      </header>
      <main>
        <Home homeRef={homeRef} />
        <About aboutRef={aboutRef} />
        <Projects projectsRef={projectsRef} />
        <Contact contactRef={contactRef}/>
      </main>
    </div>
  );
}

export default App;
